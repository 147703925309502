@import '../../Theme/Styles/variables';

.alertBox {
  position: relative;
  border: 2px solid $primary;
  border-radius: 8px;
  overflow: hidden;
  margin: 0.5rem 0;

  &__button {
    background-color: $white;
    color: initial;
    width: 100%;
    border-radius: 0;
    border: 0;
    text-align: left;
    position: relative;
    text-transform: initial;
    padding-left: 1rem;

    &:active,
    &:focus,
    &:hover {
      background-color: $white !important;
      color: initial !important;
      box-shadow: none !important;
    }

    > span:first-child {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &__chevron {
    transition: transform 0.3s ease-in;
    color: initial;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    font-size: 1.4rem;

    &Up {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__card {
    border: 0;
    padding: 1rem;

    p {
      margin-bottom: 0;
    }
  }
}
