@import '../../Theme/Styles/variables';

.tabs {
  ul {
    border-bottom: 0;
  }

  .navLink {
    cursor: pointer;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: $black;
    padding: 0.5rem 1.2rem;
    font-weight: 700;

    &:global(.active),
    &:global(.active):hover {
      background-color: transparent;
      border-bottom: 5px solid $primary;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      color: $primary;
    }

    &:hover {
      background-color: transparent;
      border-bottom: 5px solid $secondary;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}
