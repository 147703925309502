@import '../../Theme/Styles/variables';

.leftNavi {
  &__container {
    padding: 0;
  }

  &__button {
    background-color: $white;
    color: initial;
    width: 100%;
    border-radius: 0;
    border: 0;
    text-align: left;
    position: relative;
    text-transform: initial;
    padding: 18px 10px;

    &:active,
    &:focus,
    &:hover {
      background-color: $white !important;
      color: initial !important;
      box-shadow: none !important;
    }

    &:hover {
      background-color: $primary !important;

      span,
      svg {
        color: $white;
      }
    }

    > span:first-child {
      font-weight: initial;
      font-size: initial;
    }

    + div .leftNavi__link {
      padding: 12px 10px 12px 20px;
    }
  }

  &__chevron {
    transition: transform 0.3s ease-in;
    color: initial;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    font-size: 1rem;

    &Up {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__link {
    display: block;
    padding: 18px 10px;

    &:hover {
      background-color: $primary;
      color: $white;

      text-decoration: none;
    }
  }
}
