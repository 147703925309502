@use 'sass:map';
@use 'sass:color';
@import './utils/mediaQueries';

// Base

// always adjust both to same value!
$base-font-size-value: 16;
$base-font-size: 16px;

$primary-dark-green: #082e33;
$primary-light-green: #274e54;
$primary-dark-blue: #bde0f4;
$primary-light-blue: #e4f3f8;
$primary-light-blue-contrast: #ceebf4;
$primary: $primary-dark-green;

$secondary-accent-green: #7ff1c5;
$secondary-accent-yellow: #f8f282;
$secondary-accent-yellow-contrast: #eee30e;
$secondary-sand: #f8f5f1;
$secondary-dark-sand: #efe9e0;
$secondary-sand-darker: #efebe6;
$secondary: $secondary-accent-green;
$secondary-contrast: #18dc91;

$gray-100: #f2f2f2;
$gray-200: #ededed;
$gray-300: #e4ecef;
$gray-400: #d7d7d7;
$gray-500: #9d9d9c;
$gray-600: #898989;
$gray-700: #5f5f5f;
$gray-800: #28282f;
$gray-800: #28282f;
$gray-900: #949494;

$spacers: () !default;
$spacer: 1rem;

$spacers: map.merge(
  (
    6: (
      $spacer * 3
    ),
    7: (
      $spacer * 5
    ),
    8: (
      $spacer * 10
    )
  ),
  $spacers
);

// Functional

$red: #ff0000;
$orange: #ff6b6b;
$green: #77ffc5;
$yellow: #ffa800;
$black: #000;
$white: #fff;
$white-30: color.adjust($white, $lightness: -30%);
$grey: #dcdcdc;
$material-red: #d32f2f;
$material-green: #388e3c;
$material-blue: #1a237e;
$material-orange: #ffd180;
$material-purple: #aa00ff;
$material-turquoise: #80d8ff;
$material-lime: #c6ff00;
$material-light-blue: #2196f3;
$material-light-green: #b2ff59;

// Temperature

$temperature-high: #fa8e6c;
$temperature-mid: #fdcba9;
$temperature-low: #f6f2b3;
$temperature-cold: #e4f3f8;

// Precipitation

$precipitation-high: #5c8289;
$precipitation-mid: #7699a0;
$precipitation-low: #b3d7e4;
$precipitation-dry: #dffbfd;

// Default breakpoints

$default-min-breakpoint: map.get($grid-breakpoints, 'sm');
$default-max-breakpoint: map.get($grid-breakpoints, 'xxl');
$default-center-breakpoint: map.get($grid-breakpoints, 'lg');
$default-line-height: 100%;
